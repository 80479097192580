import React, { lazy, Suspense } from "react";

import "./App.css";
import Header from "components/layout/Header/Header";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import DappsLoader from "components/DappsLoader";

const Chrome = lazy(() => import("pages/Chrome"));
const LandingPage = lazy(() => import("pages/LandingPage"));
const DeveloperPage = lazy(() => import("pages/DeveloperPage"));
const UserPage = lazy(() => import("pages/UserPage"));
const ContactUs = lazy(() => import("pages/NewContactUs"));
const NewAboutUs = lazy(() => import("pages/NewAboutUsPage"));
const Guidelines = lazy(() => import("pages/Guidelines"));
const DappsEvent = lazy(() => import("pages/Event"));
const Partners = lazy(() => import("pages/TrustedPartners"));
const Ancrypto = lazy(() => import("pages/Ancrypto"));
const Showcase = lazy(() => import("pages/Showcase"));
const Termsandcondition = lazy(() => import("pages/TermsAndCondition"));
const Team = lazy(() => import("pages/Team"));

function App() {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<DappsLoader />}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/developer" element={<DeveloperPage />} />
            <Route path="/user" element={<UserPage />} />
            <Route path="/aboutus" element={<NewAboutUs />} />
            <Route path="/guidelines" element={<Guidelines />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/events" element={<DappsEvent />} />
            <Route path="/our-team" element={<Team />} />
            <Route path="/chrome" element={<Chrome />} />
            <Route path="/ancrypto.dapps" element={<Ancrypto />} />
            <Route path="/coreto.dapps" element={<Showcase />} />
            <Route path="/trusted-partners" element={<Partners />} />
            <Route path="/termsandcondition" element={<Termsandcondition />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
