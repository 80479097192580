import styled from "styled-components";

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
   position: fixed;
    background: white;
    z-index: 1111111111;
    top: 0;
    width: 100%;
`;
export const LoadingWrapper2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 108vh;
    position: fixed;
    background: white;
    z-index: 1111111111;
    top: 0;
    width: 100%;
`;
